import { Component, OnInit } from '@angular/core';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { IBaseLoginResp } from 'src/app/shared/interfaces/base-login-resp';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { MsalService } from '@azure/msal-angular';





@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(public profileInfoService: ProfileInfoService,
    private _cookieService: CookieService,
    private _router: Router,
    private _preventBack: PreventBackButtonService,
    private authService: MsalService,
  ) {
  }


  ngOnInit(): void {
    this._preventBack.preventBackButton(); //method to prevent browser back button
  }
  private userInfo(): IBaseLoginResp {
    return JSON.parse(this._cookieService.get('profileDetails'));
  }

  UserId() {
    return this.userInfo() ? this.userInfo().id : 0;
  }

  UserName() {
    return this.userInfo() ? this.userInfo().userPrincipalName : null;
  }

  DisplayName() {
    return this.userInfo() ? this.userInfo().displayName : null;
  }
  logout() {
    this._cookieService.deleteAll();
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logoutRedirect();
    // this._router.navigateByUrl('login');
  }

}
