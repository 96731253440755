import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileInfoService } from './profile-info.service';
import { ValidLoginService } from './valid-login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private _router: Router,
    private profileInfoService: ProfileInfoService,
    private _validLoginService: ValidLoginService
  ) {

  }

  //to check valid login
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //if (sessionStorage.getItem('profileDetails') != null) {
      return true;
    // }
    // else {
    //   this._router.navigate(['/']);
    //   sessionStorage.clear();
    //   return false;
    // }
  }
}
