<div class="main-body-con" id="home">
  <div class="page-container px-4 pt-3 mt-2">
    <div class="dsh-img" [class.pb-0]="!isMarq">
      <!-- <img src="assets/images/dashboard.jpg" alt="" /> -->
      <!-- <ngx-tableau tableauVizUrl="https://us-east-1.online.tableau.com/t/lambis/views/SMSHomeScreen/SMSHomeScreen"
        [options]="options"></ngx-tableau> -->
      <ngx-tableau
        tableauVizUrl="https://us-east-1.online.tableau.com/t/lambis/views/CaffeineHomeScreen/LandingPage?:embed=yes&:linktarget=_blank"
        [options]="options"></ngx-tableau>
    </div>

    <!-- <h2>{{title}}</h2> -->


    <!-- <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                <h2>Total Premium</h2>
                <div class="graph-box">
                  <img src="assets/images/graph1.svg" alt="" />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                <h2>Opportunities <span class="sub-hd">(Target Premium by Producer)</span></h2>
                <div class="graph-box">
                <img src="assets/images/graph2.svg" alt="" /></div>
            </div>
          </div> -->

  </div>
  <!-- <div class="marq flex-div space-between" [class.n-marq]="!isMarq">
      <marquee width="97%" direction="left" height="auto" scrollamount="5"><span>New client</span> : Youth Villages Inc <span class="ps-5">New client</span> : York Electric Cooperative Inc <span class="ps-5">New client</span> : CAPITAL DISTRICT DDSO <span class="ps-5">New policy bound for</span> : Young Adult Institute, Inc. <span class="ps-5">New policy bound for</span> : Zoological Society of San Diego <span class="ps-5">New policy bound for</span> : YMCA of Greater New York</marquee>

      <a _ngcontent-rfu-c709="" href="javascript:void(0)" title="" (click)="closeMarq()"  class="close"><img _ngcontent-rfu-c709="" src="assets/images/closey.svg" alt=""></a>
    </div> -->

  <!-- <div class="marq flex-div space-between" [class.n-marq]="!isMarq">
      <marquee width="95%" direction="right" height="auto" scrollamount="5"><span>New client</span> : ABC Corp <span class="ps-5">New client</span> : CAPITAL DISTRICT DDSO <span class="ps-5">New policy bound for</span> : CAPITAL DISTRICT DDSO</marquee>

      <a _ngcontent-rfu-c709="" href="javascript:void(0)" title="" (click)="closeMarq()"  class="close"><img _ngcontent-rfu-c709="" src="assets/images/closew.svg" alt=""></a>
    </div> -->

</div>
