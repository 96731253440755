import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
//import { Router } from '@angular/router';
import { RegxConstant } from 'src/app/shared/class/regx';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { AddprospectScreen } from 'src/app/features/prospect/error-message/addprospect-screen';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { IcreateQuote } from 'src/app/features/prospect/interfaces/icreate-quote';

@Component({
  selector: 'app-request-a-quote',
  templateUrl: './request-a-quote.component.html',
  styleUrls: ['./request-a-quote.component.css']
})
export class RequestAQuoteComponent implements OnInit {
  createQuoteForm: any;
  bsValue = new Date();
  errorList: any;
  submitted: boolean = false;
  coverageTypeList: any = [];
  invalidExpirationDate: boolean = false;
  _AddprospectScreen: AddprospectScreen;
  phoneInvalid: boolean = false;
  _regxConstant: RegxConstant;


  constructor(
    private fb: UntypedFormBuilder,
    //private _router: Router,
    private _AddprospectService: AddprospectService,
    public datepipe: DatePipe,
    public ValidateKey: KeyboardValidation,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,

  ) {
    this._AddprospectScreen = new AddprospectScreen();
    this._regxConstant = new RegxConstant();
    this.createQuoteForm = this.fb.group({
      companyName: ['Non-Profits Org', [Validators.required]],
      contactName: ['Chris Green', [Validators.required]],
      phoneNumber: ['5185474386', [Validators.required]],
      email: ['Lillard07@mailinator.com', [Validators.required, , Validators.pattern(this._regxConstant.Email())]],
      coverageType: [''],
      currentCarrier: ['GOLD TEAMQUAD'],
      expirationDate: ['10/12/2022']
    })
  }

  ngOnInit(): void {

  }

  // save Quote data
  saveQuote() {

    this.submitted = true;
    let data = this.createQuoteForm.value;

    if (this.createQuoteForm.invalid) {
      return
    }
    let saveData: IcreateQuote = {
      prospectName: data.companyName ? data.companyName : '',
      contactName: data.contactName ? data.contactName : '',
      phoneNumber: data.phoneNumber ? data.phoneNumber : '',
      emailAddress: data.email ? data.email : '',
      city: 'Chicago',
      state: 'IL',
      zipCode: '302020'
    }

    this._AddprospectService.saveQuote(saveData).subscribe(resp => {
      if (resp) {
        alert("Prospect created successfully.")
        this.createQuoteForm.reset();
        this.submitted = false;
      }
    }, error => {
      let obj = error.error.errors;
      var arr = Object.keys(obj).map(key => (
        { type: key, value: obj[key] }
      ))
      alert(JSON.stringify(arr));
    })
  }

  get f() {
    return this.createQuoteForm.controls;
  }

  get PhoneNumber(): any {
    return this.createQuoteForm.get('phoneNumber')
  }

  get Email(): any {
    return this.createQuoteForm.get('email')
  }

  // Remove white spaces from every input fileds
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

}
