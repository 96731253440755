<div class="main login-outer1">

    <div class="login-container">
        <div class="login-logo"><img src="../assets/images/logo-white.png"></div>
        <!-- <div class="login-txt">
  
        <span>Welcome to Our Insurance System. </span>
        <h1>Login Your Account</h1>
  
        <p>Click on below button to login with your microsoft email address.</p>
        <div class="loginbtn" (click)="login()"><a title="" class="btn btn-yellow col-3">Get Started</a></div>
      </div> -->

        <div class="login-txt pt-5">
            <h1>Unauthorized User, Try again.</h1>
            <div class="loginbtn"><a title="" class="btn btn-yellow col-3" (click)="logout()">Logout</a></div>
        </div>
        <!-- <p class="login-footer">{{this._userMessages.footerText}}</p> -->

    </div>
</div>