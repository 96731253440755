import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { NavigateService } from 'src/app/shared/services/navigate.service';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { SelectedHeaderService } from 'src/app/shared/services/selected-header.service';
import { ValidLoginService } from 'src/app/shared/services/valid-login.service';
import { LoaderService } from 'src/app/shared/utility/loader/loader.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { ToolbarPosition, VizCreateOptions } from 'ngx-tableau';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  options: VizCreateOptions = {
    hideTabs: true,
    hideToolbar: false,
    disableUrlActionsPopups: true,
    toolbarPosition: ToolbarPosition.TOP,
    onFirstInteractive: (event: any) => {
    },
  };
  // Loaded event
  handleOnLoaded = (loaded: any) => console.log('Loaded', loaded);
  isValidLogin: any;
  title = 'Dashboard';
  num = 5;
  _navigationPath: NavigationPath;
  apiResponse: any = "";
  finalApiResponse: any;
  loginDisplay = false;
  isMarq = true;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private _customNotifireService: CustomNotifireService,
    private _validLoginService: ValidLoginService,
    private _router: Router,
    private _navigateService: NavigateService,
    private http: HttpClient,
    private _cookieService: CookieService,
    private _loaderService: LoaderService,
    private _preventBack: PreventBackButtonService,
    private _selectedHeaderService: SelectedHeaderService,
    private titleService: Title
  ) {
    this._navigationPath = new NavigationPath();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  navigate(comp: any) {
    this._selectedHeaderService.setSelectedHeader('homeRecall');
    this._navigateService.navigateComp(comp);
  }

  closeMarq() {
    this.isMarq = !this.isMarq;
  }
}
