import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from '../shared/services/auth.guard';
import { MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProfileComponent } from './components/profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DialsComponent } from './components/dials/dials.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import {NgxMaskDirective, NgxMaskPipe} from 'ngx-mask';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { RequestAQuoteComponent } from './components/request-a-quote/request-a-quote.component';
import { RingCentralLoginComponent } from './components/ring-central-login/ring-central-login.component';
import { RingCentralCodeComponent } from './components/ring-central-code/ring-central-code.component';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

@NgModule({
  declarations: [LoginComponent, ProfileComponent, DialsComponent, RequestAQuoteComponent, RingCentralLoginComponent, RingCentralCodeComponent, UnauthorizedComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    DialogModule,
    ScrollPanelModule,
    DateInputsModule,
    TooltipModule,
    AutoCompleteModule,
    DragDropModule,
    NgxMaskPipe,
    NgxMaskDirective
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },

    AuthGuard,
    DatePipe,
    CurrencyPipe,
  ],
})
export class CoreModule { }
