import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialsService } from 'src/app/features/prospect/components/prospect-tab/services/dials.service';
import { UserManagementService } from 'src/app/features/user-management/services/user-management.service';

@Component({
  selector: 'app-ring-central-code',
  template: `
  <div class="alert alert-success" role="alert">
  <h3 class="alert-heading mb-3">Logged in Successfully</h3>
  <p class="mb-3"><b>The window will be closed automatically</b></p>
</div>
  `,
  styles: [`
   .alert-success {
    color: #155724;
    background-color: white !important;
    border-color: #c3e6cb;
}
  `]
})
export class RingCentralCodeComponent implements OnInit {
  rcLoginUrl: any;
  userDetailes: any;
  userId: any;
  userDetails: any;

  constructor(
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private dialsService: DialsService,
    private _userManagementService: UserManagementService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((param: any) => {
      this.setRCtoken(param)
    })
  }

  setRCtoken(rcToken: any) {
    if (rcToken) {
      this.dialsService.setRCAuthToken(rcToken).subscribe((resp: any) => {
        if (resp) {
          // localStorage.setItem('modalStatus', 'close')
          var todayDate = new Date();
          var authTokenValue = JSON.parse(resp.authToken)
          todayDate.setSeconds(todayDate.getSeconds() + authTokenValue.expires_in)
          localStorage.setItem('RC_tokenExp', todayDate.getTime().toString())
          localStorage.setItem('RC_token', resp.authToken)
          this.userDetailes = JSON.parse(localStorage.getItem('userDetails')!);
          if (this.userDetailes) {
            this.userId = this.userDetailes.user.userId
          }

          const payload = {
            UserId: this.userId,
            RingCentralToken: resp.authToken
          }
          this.dialsService.saveRCAuthToken(payload).subscribe((resp: any) => {
            if (resp) {
              this.getUserDetails(this.userId);
            }
          })

        }
      })

    } else {
      localStorage.setItem('modalStatus', 'open')
    }
  }

  //get user detail method
  getUserDetails(id: any) {
    if (id) {
      this._userManagementService.getUserById(id).subscribe((resp) => {
        this.userDetails = resp;
        localStorage.setItem('RC_token', this.userDetails.user.rcTokenObject);
        localStorage.setItem('RC_tokenExp', JSON.stringify(this.userDetails.user.rcTokenDateTime));
        // localStorage.setItem('modalStatus', 'close')
        var timer = setTimeout(() => {
          localStorage.setItem('modalStatus', 'close')
          window.close();
          clearInterval(timer)
        }, 2000);

      });
    }
  }

}
