import { Component, OnInit, Inject } from '@angular/core';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { RedirectRequest } from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public isValidLogin: any;
  public apiResponse: any = "";
  public finalApiResponse: any;
  public IsLoginRedirected: string = 'false';
  public _navigationPath: NavigationPath;
  public _userMessages: UserMessages;
  public loginDisplay = false;
  public unsubscribe$: Subject<boolean> = new Subject();
  public subscriptions: any = [];
  public accessInformation: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private _cookieService: CookieService,
    private _preventBack: PreventBackButtonService
  ) {
    this._navigationPath = new NavigationPath();
    this._userMessages = new UserMessages();
  }

  ngOnInit(): void {
    this._cookieService.deleteAll();
    let appointmentView = localStorage.getItem('AppointmentEmail')
    localStorage.clear();
    localStorage.setItem('AppointmentEmail', JSON.stringify(appointmentView));
    sessionStorage.clear();
    this._preventBack.preventBackButton(); //method to prevent browser back button
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
}