import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../shared/components/layout/layout.component';
import { HomeComponent } from '../features/home/home.component';
import { AuthGuard } from '../shared/services/auth.guard';
import { NavigationPath } from '../shared/class/navigation-path';
import { LoginComponent } from './components/login/login.component';
import { RequestAQuoteComponent } from './components/request-a-quote/request-a-quote.component';
import { RingCentralCodeComponent } from './components/ring-central-code/ring-central-code.component';
import { FormGuradGuard } from '../shared/services/form-gurad.guard';
import { UnauthorizedComponent } from '../features/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'request-a-quote', component: RequestAQuoteComponent },
  { path: 'RingCentral/AuthCallBack', component: RingCentralCodeComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: NavigationPath.dashboard, component: HomeComponent },
      { path: '', component: HomeComponent },

      {
        path: NavigationPath.prospects,
        loadChildren: () =>
          import('../features/prospect/prospect.module').then(
            (m) => m.ProspectModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.system,
        loadChildren: () =>
          import('../features/system-manager/system-manager.module').then(
            (m) => m.SystemManagerModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.contacts,
        loadChildren: () =>
          import('../features/contact/contact.module').then(
            (m) => m.ContactModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.whiteboards,
        loadChildren: () =>
          import('../features/white-board/white-board.module').then(
            (m) => m.WhiteBoardModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.settings,
        loadChildren: () =>
          import('../features/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.prospectsRecall,
        loadChildren: () =>
          import('../features/recalls/recalls.module').then(
            (m) => m.RecallsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.admin,
        loadChildren: () => import('../features/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.tags,
        loadChildren: () => import('../features/tags/tags.module').then(m => m.TagsModule),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.carriermanagement,
        loadChildren: () =>
          import(
            '../features/carrier-management/carrier-management.module'
          ).then((m) => m.CarrierManagementModule),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.listManagement,
        loadChildren: () =>
          import('../features/list-management/list-management.module').then(
            (m) => m.ListManagementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.appointment,
        loadChildren: () =>
          import('../features/appointment/appointment.module').then(
            (m) => m.AppointmentModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.tags,
        loadChildren: () =>
          import('../features/tags/tags.module').then((m) => m.TagsModule),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.userPermission,
        loadChildren: () =>
          import('../features/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.rolePermission,
        loadChildren: () =>
          import('../features/role-management/role-management.module').then(
            (m) => m.RoleManagementModule
          ),
        canActivate: [AuthGuard],
      },

      {
        path: NavigationPath.masterData,
        loadChildren: () =>
          import('../features/master-data/master-data.module').then(
            (m) => m.MasterDataModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: NavigationPath.acme,
        loadChildren: () =>
          import('../features/acme/acme.module').then(
            (m) => m.AcmeModule
          ),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
