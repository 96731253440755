<style>
  @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
</style>
<div class="header-quote">
  <img src="../assets/images/quote/hdr.jpg" />
</div>

<div id="rqst">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6">
        <h2>Request a Quote</h2>
        <div class="left-form">
          <form novalidate method="post" [formGroup]="createQuoteForm" class="mrgn-top1">
            <div class="frmrow">
              <div class="has-validation">
                <label>Company Name<span style="color: red">*</span></label>
                <input formControlName="companyName" [class.errorInput]="f.companyName.invalid && submitted"
                  class="form-control" placeholder="Company Name" dataTextFocus
                  (focusout)="removeSpaces($event, f.companyName)" />
                <div class="display-error" *ngIf="f.companyName.invalid && submitted">
                  <span *ngIf="f.companyName.errors?.required">{{
                    _AddprospectScreen.companyName.required
                    }}</span>
                  <span *ngIf="f.companyName.errors?.maxlength">{{
                    _AddprospectScreen.companyName.maxlength
                    }}</span>
                </div>
              </div>
            </div>
            <div class="frmrow">
              <div class="has-validation">
                <label>Contact Name<span style="color: red">*</span></label>
                <input [class.errorInput]="f.contactName.invalid && submitted" type="text" class="form-control"
                  formControlName="contactName" placeholder="Contact Name" />
                <div class="display-error" *ngIf="f.contactName.invalid && submitted">
                  <div>
                    <span *ngIf="f.contactName.errors?.required">
                      {{ _AddprospectScreen.contactName.required }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row frmrow">
                      <div class="col-12 col-sm-12 col-md-6  mb-1 has-validation">
                          <label>Contact Name</label>
                          <input [class.errorInput]="f.contactName.invalid && submitted" type="text" value="test2"
                              class="form-control" formControlName="contactName">
                          <div class="display-error" *ngIf="f.contactName.invalid && submitted">
                              <div>
                                  <span *ngIf="f.contactName.errors?.required">
                                      {{_AddprospectScreen.contactName.required}}
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div> -->
            <div class="frmrow">
              <div class="has-validation">
                <label>Phone Number<span style="color: red">*</span></label>
                <input name="phoneNumber" class="form-control" formControlName="phoneNumber" placeholder="Phone Number"
                  (keypress)="ValidateKey.WholeNumbers($event, 10)" />

                <div class="display-error" *ngIf="f.phoneNumber.invalid && submitted">
                  <span *ngIf="f.phoneNumber.errors?.required">
                    {{ _AddprospectScreen.phoneNumber.required }}
                  </span>
                </div>
              </div>
            </div>
            <div class="frmrow">
              <div class="has-validation">
                <label>Email</label>
                <input formControlName="email" [class.errorInput]="f.email.invalid && submitted" class="form-control"
                  placeholder="Email" (focusout)="removeSpaces($event, f.email)" />
                <div class="display-error" *ngIf="f.email.invalid && submitted">
                  <span *ngIf="f.email.errors?.pattern">
                    {{ _AddprospectScreen.email.pattern }}
                  </span>
                </div>
              </div>
            </div>

            <div class="frmrow">
              <div class="has-validation date-control">
                <label>Expiration Date</label>
                <p-calendar class="form-control" [showIcon]="true" [iconDisplay]="'input'" placeholder="MM/DD/YYYY"
                  formControlName="expirationDate" dateFormat="mm/dd/yy" inputId="expirationDate" [showIcon]="true" [iconDisplay]="'input'">
                </p-calendar>
              </div>
            </div>
            <div class="frmrow">
              <div class="has-validation">
                <label>Coverage Type</label>
                <select class="select-cs form-select">
                  <option value="Coveragetype">-Select-</option>
                  <option value="Coveragetype">Worker's Compensation</option>
                  <option value="Coveragetype">Package</option>
                  <option value="Coveragetype">Benefits</option>
                </select>
              </div>
            </div>

            <div class="frmrow">
              <div class="has-validation">
                <label>Current Carrier</label>
                <input type="text" class="form-control" formControlName="currentCarrier"
                  placeholder="Current Carrier" />
              </div>
            </div>
            <div class="frmrow">
              <div class="btn-block-quote">
                <!-- <button class="btn btn-outline-primary px-4 py-2 me-3">
                  Cancel
                </button> -->
                <button class="btn-quote mg-tp" (click)="saveQuote()">
                  Request Free Quote
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-6">
        <h2>Contact Us</h2>
        <img src="../assets/images/quote/con-hd.jpg" class="mrgn-top" />
      </div>
    </div>
  </div>
  <div class="footer-quote mg-tp1">
    <img src="../assets/images/quote/ftr-img.jpg" />
  </div>
</div>
